body {
  margin: 0;
  padding-top: 3.5rem;
  font-family: "Open Sans", arial, sans-serif !important;
}

.section-height {
  height: 85vh;
}

@media screen and (max-width: 522px) {
  .section-height {
    height: 80vh;
  }
  h1 {
    font-size: 1.5rem !important;
  }
  .display-4 {
    font-size: 2.5rem !important;
  }
}
@media screen and (max-width: 400px) {
  .section-height {
    height: 85vh;
  }
}

html {
  font-size: 1.1rem;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1.2rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1.4rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1.6rem;
  }
}

/* Text Alignment  */
.text-xs-left {
  text-align: left !important;
}

.text-xs-right {
  text-align: right !important;
}

.text-xs-center {
  text-align: center !important;
}

.text-xs-justify {
  text-align: justify !important;
}

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .text-sm-justify {
    text-align: justify !important;
  }
}

@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .text-md-justify {
    text-align: justify !important;
  }
}

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .text-lg-justify {
    text-align: justify !important;
  }
}
