.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}

.card-body {
  background-color: #343a40 !important;
  color: rgb(133, 194, 64);
}
