.background-img {
  background-image: linear-gradient(
      rgba(21, 21, 21, 0.7),
      rgba(21, 21, 21, 0.7)
    ),
    url("../assets/roof-4125765_1920.jpg");
  background-size: cover;
  color: white;
}
.text-padding {
  padding-top: 15vh;
}

@media screen and (max-width: 522px) {
  .text-padding {
    padding-top: 8vh;
  }
}
@media screen and (max-width: 400px) {
  .text-padding {
    padding-top: 5vh;
  }
}

@media screen and (max-width: 320px) {
  .text-padding {
    padding-top: 4vh;
  }
  .center-jumbo-text {
    top: 60% !important;
  }
}

.center-jumbo-text {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.sub-jumbo-text {
  color: rgb(133, 194, 64);
}
